/*================================================================================
	Item Name: vep - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//defaults
$theme-green-bg: #2eb67d !default;
$theme-grey-bg: #f8f8f8 !default;
$theme-black-bg: #000000 !default;
.greenBackground {
    background-color: $theme-green-bg;
}
.blackBackground {
    background-color: $theme-black-bg;
}
.backgroundImage1 {
    background-image: url('../images/register_1.png');
    background-size: cover;
}
.backgroundImage2 {
    background-image: url('../images/register_2.png');
    background-size: cover;
}
.backgroundImage3 {
    background-image: url('../images/login_1.png');
    background-size: cover;
}
.greyBackground {
    background-color: $theme-grey-bg !important;
}
.whiteBackground {
    background-color: white !important;
}
.auth_image {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
}
.mr-15-per {
    margin: 15% !important;
}
.mr-1 {
    margin-right: 1rem;
}
.px-2rem {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
.auth_image_card {
    border: 2px solid black;
    border-radius: 10px;
    width: 350px;
}
.auth_image_card2 {
    border: 2px solid black;
    border-radius: 10px;
    width: 350px;
    min-width: 470px;
    margin-bottom: 0px !important;
}

.auth_image_border_radius {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.auth_image_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: lowercase;
    text-align: initial;
    color: #000000;
}
.auth_image_text_2 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
}
.auth_image_text_3 {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    line-height: 21px;
}
.register_section_1 {
    margin-left: 64px;
    padding-left: 20px;
    padding-bottom: 25px;
    padding-top: 12px;
    border-left: 1px solid #e1ffcf;
}
.register_section_2 {
    border-bottom: 1px solid #e1ffcf;
    margin-left: 64px;
    width: 282px;
}
.register_section_3 {
    color: #e1ffcf;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 116.667% */
    width: 430px;
}
@media only screen and (max-width: 1200px) {
    .register_section_3 {
        width: 300px !important;
    }
}
.register_section_4 {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 175% */
}
.register_section_5 {
    padding-top: 40px;
    padding-left: 64px;
}
.register_section_6 {
    // margin-top: 30rem;
    // width: 600px;
    position: relative;
    bottom: 50px;
}
.register_text_11 {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 105% */
}
.register_icon_1 {
    margin-left: 10px;
    height: 40px;
}
.register_icon_2 {
    margin-left: 5px;
    height: 37px;
}
.register_text_13 {
    font-weight: 300;
}
@media only screen and (max-width: 768px) {
    .sectionHeader_div_flex_3 {
        padding-left: 1.8rem !important;
        padding-right: 1.8rem !important;
    }
    .register_text_11 {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 131.25% */
    }
    .register_text_12 {
        font-size: 16px !important;
        max-width: 150px !important;
    }
    .register_icon_1 {
        margin-left: 10px;
        height: 20px;
    }
    .register_icon_2 {
        margin-left: 5px;
        height: 24px;
    }
    .register_text_13 {
        font-size: 12px;
    }
}
.register_text_12 {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 105% */
}
.register_test_13 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.register_test_14 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0.5rem;
}
.register_test_15 {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.5rem;
}
.register_section_7 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 47px;
    background: black;
    padding-top: 34px;
    padding-bottom: 24px;
}
.register_section_8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
@media only screen and (max-width: 768px) {
    .register_section_7 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .register_section_8 {
        padding-left: 24px !important;
        padding-right: 24px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .register_section_9 {
        display: flex;
        flex-direction: column;
    }
    .d_none_under_768 {
        display: none;
    }
}
.auth_image_text_sub {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #5e5873;
    text-align: initial;
    margin-top: 10px;
    margin-left: 10px;
}

.auth_image_icon_div {
    position: relative;
}
.auth_image_icon {
    position: absolute;
    top: -50px;
    right: -50px;
}
.auth_card_image_icon {
    width: 125px;
    padding-left: 2rem;
    padding-top: 2rem;
}

.auth_card_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #2eb67d;
    padding-left: 2rem;
    padding-top: 2rem;
}
.bordered_card {
    border: 1px solid black;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background-color: white;
    min-width: 400px;
    margin-bottom: 0px;
}
.bordered_card_2 {
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background-color: white;
    min-width: 400px;
    margin-bottom: 0px;
}
.divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.bold {
    font-weight: 800;
}

.auth_display_flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.auth_display_flex_3 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.sectionHeader_div_1 {
    height: 1px;
}
.sectionHeader_div_2 {
    display: none;
}
.sectionHeader_div_3 {
    position: relative;
    top: -30px;
}
@media only screen and (max-width: 768px) {
    .auth_display_flex_3 {
        justify-content: center !important;
    }
    .sectionHeader_div_1 {
        display: none;
    }
    .sectionHeader_div_2 {
        display: flex;
        // margin-top: 1rem;
    }
    .sectionHeader_div_3 {
        position: unset;
        top: auto;
        margin-top: 1rem;
    }
}
.auth_display_flex_2 {
    display: flex;
    align-items: center;
    width: 100%;
}
.ml-1 {
    margin-left: 1rem !important;
}
.auth_or {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    letter-spacing: 0.4px;
    color: #5e5873;
}

.success_btn {
    background-color: #2eb67d !important;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 10px;
    min-width: 140px;
    max-width: 250px;
    border-width: 1px 1px 3px 3px;
    height: 54px;
}
.success_btn:active {
    background-color: #2eb67d !important;
}
.success_btn:focus {
    background-color: #2eb67d !important;
}

.success_btn_sm {
    background: #2eb67d !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.success_btn_sm:active {
    background-color: #2eb67d !important;
}

.success_btn_sm:focus {
    background-color: #2eb67d !important;
}
.info_btn {
    background: #e6f6ef !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 10px;
    min-width: 140px;
    max-width: 250px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
}
.info_btn:active {
    background-color: #e6f6ef !important;
}

.info_btn:focus {
    background-color: #e6f6ef !important;
}
.info_btn_sm {
    background: #e6f6ef !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 10px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info_btn_sm:active {
    background-color: #e6f6ef !important;
}

.info_btn_sm:focus {
    background-color: #e6f6ef !important;
}

.auth_card_footer {
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid black;
    padding-bottom: 2rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #5e5873;
}
.auth_card_link {
    color: #7367f0;
    font-weight: 800;
}

.auth_icon_Inline {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}
.flex_center {
    justify-content: center;
    display: flex;
    align-items: center;
}
.flex_center_2 {
    justify-content: center;
    display: flex;
}
.image_icon_v1 {
    position: absolute;
    top: -60px;
    right: -20px;
}
.image_icon_v2 {
    position: absolute;
    top: -55px;
    left: -20px;
}
.image_icon_v3 {
    position: absolute;
    top: -60px;
    left: -20px;
}
.image_icon_v4 {
    position: absolute;
    top: -55px;
    right: -20px;
}
.image_v1_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #000000;
}
.black_btn {
    background: #000000 !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-size: 20px;
}
.black_btn:active {
    background-color: #000000 !important;
}

.black_btn:focus {
    background-color: #000000 !important;
}
.pl-2 {
    padding-left: 2rem !important;
}
.pr-2 {
    padding-right: 2rem !important;
}
.plt-1 {
    padding-left: 1rem;
    padding-top: 1rem;
}
.pxy-1 {
    padding: 1rem;
}

.email_verify_grey_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #5e5873;
}
.black {
    color: black;
}

.email_verify_sub_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.email_verify_text_area {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
    max-width: 420px;
}

.email_verify_email {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.email_verify_label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6e6b7b;
}

.resendBtn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7367f0;
    cursor: pointer;
}

.pass_verify_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}
.pass_verify_label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #c4c4c4;
}

.pass_verify_sub_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.pass_verify_title_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.pass_verify_p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}
.pass_verify_p_green {
    color: #2eb67d;
    font-weight: 800;
}
.pass_verify_check_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.profile_creation_text_1 {
    font-family: 'Montserrat';
    margin-top: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.profile_creation_text_2 {
    margin-top: 0.5rem;
    max-width: 550px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.file_uploader_card {
    border: 1px dashed #2eb67d;
    border-radius: 10px;
}
.file_uploader_div1 {
    cursor: pointer;
}
.file_uploader_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2eb67d;
    margin-left: 10px;
}
.profile_creation_nav {
    background: #2eb67d !important;
    border-bottom: 1px solid #000000;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 1rem;
}

.profile_creation_app_content {
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.header_direction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_direction_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    color: #000000;
}

.padding_2 {
    padding: 2rem;
}

.next_page_card {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    margin-bottom: 0px;
    border: 1px solid black;
}
.next_page_card_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.next_page_card_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}

.next_page_card_text_3 {
    color: #c4c4c4;
}

.min_width_600 {
    min-width: 600px !important;
}

.radio_flex {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-top: 0.7rem;
}
.display_flex_center {
    display: flex;
    align-content: center;
    align-items: center;
}

.mb-half {
    margin-bottom: 0.5rem;
}
.hourly_charge_text_4 {
    color: #6e6b7b;
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    margin-left: 2.5rem;
    margin-top: 0.5rem;
}
.profile_file_uploader_div {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    background: #d9d9d9;
    border: 0px;
}

.profile_file_uploader_image {
    width: 150px;
    height: 150px;
    border-radius: 50% !important;
    cursor: pointer;
}

.profile_flex_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .profile_flex_column {
        align-items: inherit !important;
    }
    .hourly_charge_text_4 {
        font-size: 12px;
    }
}
.error-message {
    color: red;
}

.display_flex_center_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.profile_creation_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}

.sectionHeader_div_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.sectionHeader_div_flex_2 {
    border-bottom: 1px solid #c4c4c4;
}
.outline_btn2 {
    border-radius: 5px;
    border: 1px solid #a9a5a5 !important;
    padding: 5px !important;
}
.border_color_grey_2 {
    border-radius: 5px;
    border: 1px solid #a9a5a5 !important;
    padding: 5px !important;
}
.sectionHeader_div_flex_4 {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 1rem !important;
    opacity: 0.8;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4c4c4;
}
.success_btn_sm_2 {
    background: #2eb67d !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset;
    border-color: #2eb67d !important;
}
.success_btn_sm_2:active {
    background-color: #2eb67d !important;
}

.success_btn_sm_2:focus {
    background-color: #2eb67d !important;
}
.success_btn_sm_2:hover {
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset !important;
}
.sectionHeader_div_flex_3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.5rem;
}
.sectionHeader_div_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    color: #000000;
    padding-left: 5px;
}
.sectionHeader_div_title_2 {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    padding-left: 10px;
}
.sectionHeader_div_title_3 {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.display_flex_space_between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.display_flex_center_space_between {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.display_flex_center_column {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}
.social_link_element_flex {
    background: #f8f8f8;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.hourly_charge_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.hourly_charge_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6e6b7b;
    margin-top: 1rem;
}

.hourly_charge_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #c4c4c4;
}

.hourly_charge_type {
    width: 105px;
    margin-top: 2rem !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
    background: #2eb67d;
    border-radius: 10px;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single_qual_flex {
    display: flex;
    margin-bottom: 1rem;
}

.single_qual_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.single_qual_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.single_qual_text3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.ql-container.ql-snow {
    height: 150px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.single_qual_text4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.single_qual_edit {
    width: 70px;
    padding-left: 5px;
}

.single_qual_checkbox {
    display: flex;
    align-items: center;
}

.single_qual_text5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #6e6b7b;
    margin-left: 5px;
    margin-bottom: 0px;
}

.single_exp_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-bottom: 0.5rem;
}
.single_exp_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.single_exp_flex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 480px;
    margin-bottom: 1rem;
}

.single_project_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7367f0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.single_project_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
}
.single_project_text3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0.3rem;
}

.single_skill_flex_outer {
    display: flex;
    flex-wrap: wrap;
}

.single_skill_flex_outer > * {
    width: calc(50% - 10px);
    margin: 5px;
}

.single_skill_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 160px;
}

.single_skill_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}

.navbarButton {
    background: #e6f6ef;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    height: 50px;

    display: flex;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .navbarButton {
        width: 210px;
    }
}
.nav_btn_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    padding-left: 10px;
}

.nav_btn_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-left: 10px;
}

.nav_btn_arrow {
    width: 12px;
    box-shadow: none !important;
}

.horizon2_flex {
    padding: 7rem 1rem 1rem 1rem;

    position: relative;
    transition: 300ms ease all;
    backface-visibility: hidden;
    min-height: calc(100% - 3.35rem);
}

@media (min-width: 768px) {
    .horizon2_flex {
        padding: 12rem 1rem 1rem 1rem;
    }
}

.horizon2_flex_nav {
    display: flex !important;
    margin-top: 30px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    width: auto !important;
}

.horizon2_active_nav {
    background: #6eb77d !important;
    box-shadow: 0 0 6px 1px #6eb77d99 !important;
    border-radius: 4px;
    color: #fff;
}

.sectionHeader_v2_div_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.single_interview_box {
    background-color: #ccc;
    padding: 20px;
}
.single_interview_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.single_interview_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #6e6b7b;
}
.single_interview_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.bordered_box {
    border: 1px solid black;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background-color: white;
    margin-bottom: 0px;
    width: 450px;
}

.single_interview_container {
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        450px
    ); /* creates as many columns as will fit */
    grid-gap: 20px; /* adds some space between each item */
}
.single_interview_container_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.single_interview_waiting_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #5e5873;
}
.single_interview_waiting_text_2 {
    color: #2eb67d;
}
.single_interview_waiting_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #d8d6de;
}
.border_top_black {
    border-top: 1px solid black;
    min-height: 75px;
}
.border_black_back_green {
    border-top: 1px solid black;
    background: #2eb67d;
    min-height: 75px;
}
.single_interview_waiting_text_4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
}
.single_interview_waiting_text_5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ea0e0e;
}
.search_nav_item {
    padding-left: 2px !important;
    padding-right: 2px !important;
    margin-right: 15px !important;
    font-size: 16px !important;
}
.search_nav {
    border-bottom: 1px solid !important;
    border-radius: 0px !important;
}
.single_client_user_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}
.display_flex_col_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register_image2 {
    position: fixed;
    top: 0px;
    right: 58.5%;
}
.register_test_1 {
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.register_text_2 {
    color: #2eb67d;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.success_tab_btn_sm {
    border-radius: 10px;
    border-width: 1px;
    background: #2eb67d !important;
    border-style: solid;
    border-color: #2eb67d !important;
    display: flex;
    align-items: center;
    padding: 10px 15px !important;
    justify-content: center;
}
.success_btn_number {
    width: 16px;
    background: white;
    border-radius: 50%;
    color: #2eb67d;
    padding: 1px;
    font-size: 14px;
    margin-right: 8px;
}
.plain_btn_number {
    width: 16px;
    background: #000000;
    border-radius: 50%;
    color: white;
    padding: 1px;
    font-size: 14px;
    margin-right: 8px;
}
.success_tab_btn_sm:active {
    background-color: #2eb67d !important;
}

.success_tab_btn_sm:focus {
    background-color: #2eb67d !important;
}
.plain_tab_btn_sm {
    border-radius: 10px;
    border-width: 1px;
    padding: 10px 15px !important;
    background: #ffffff !important;
    color: #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.plain_tab_btn_sm:active {
    background-color: #ffffff !important;
}

.plain_tab_btn_sm:focus {
    background-color: #ffffff !important;
}
.register_text_3 {
    color: #5e5873;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.register_text_4_link {
    color: #365ff0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-decoration-line: underline;
}
.register_text_5 {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.register_text_6 {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.register_text_7 {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.register_text_8 {
    color: #000;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.register_text_9 {
    color: #5e5873;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.register_text_10 {
    cursor: pointer;
    color: #365ff0;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}
.radio_flex_basic_details {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-top: 0.7rem;
}

.text_btn_1 {
    color: #6e6b7b;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}
